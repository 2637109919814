.review-container {
  display: flex;
  width: 100%;
  flex-wrap: wrap; /* Allow cards to wrap to the next row on smaller screens */
  justify-content: center; /* Center-align cards horizontally */
}

.review-card {
  display: grid;
  height: 436px;
  text-align: center;
  flex: 1;
  padding: 24px;
  width: 100%;
  align-items: center;
  justify-items: center;
}

.star {
  font-size: 26px;
}

.star .filled-star {
  background: 'yellow';
}

.review-card .link {
  font-size: 14px;
  color: blue;
  margin-top: 10px;
  cursor: pointer;
}

.carousel-container {
  width: 100%;
  display: flex;
}

.react-multiple-carousel__arrow--left {
  left: 5px; /* Adjust the value to move the arrow further left */
}

.react-multiple-carousel__arrow--right {
  right: 5px; /* Adjust the value to move the arrow further right */
}

@media screen and (max-width: 768px) {
  .react-multiple-carousel__arrow--left {
    left: 0px; /* Adjust the value to move the arrow further left */
  }

  .react-multiple-carousel__arrow--right {
    right: 0px; /* Adjust the value to move the arrow further right */
  }
}
