.table-count {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 5px;
}

.orders-action-menu {
  display: flex;
  margin-bottom: 20px;
}

.orders-action-menu .menu-item {
  margin-right: 5px;  
}


@media screen and (max-width: 768px) {
  .orders-action-menu {
    display: grid;
  }
  .orders-action-menu .menu-item {
    margin-right: 0px;  
    margin-bottom: 10px;  
  }
  
}
