.live-stream-container {
  display: flex;
  width: 100%;
}

.column-left {
  flex-basis: 70%;
}

.column-right {
  padding: 30px;
  flex-basis: 30%;
  background-color: #f3f8ff;
}

.loading-spinner {
  width: 40px;
  height: 40px;
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  border-top: 4px solid #3498db;
  animation: spin 1s linear infinite;
  margin-top: 20px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
