.confirmation-page {
  height: calc(100vh - 180px);
  display: grid;
  align-content: center;
}

.confirmation-page .title {
 display: flex;
 flex-direction: column;
 align-items: center;
 margin-bottom: 30px;

}

.confirmation-page .confirmation-title{
  font-size: 35px;
  font-weight: 600;
  margin-bottom: 15px;
  line-height: 40px;
}

.confirmation-page .confirmation-subtitle {
  font-size: 16px;
}

@media screen and (max-width: 768px) {
  .confirmation-page {
    height: calc(100vh - 220px);
  }
  .confirmation-page .confirmation-title{
    font-size: 24px;
  }
}

