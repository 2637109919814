.timer {
  font-weight: 900;
  background: orange;
  padding: 0 5px;
  border-radius: 5px;
}

.timer-text {
  font-size: 16px;
  color: white;
  font-weight: 600;
}
