.orders-detail {
  display: grid;
  grid-gap: 5px;
}

.orders-detail .order-detail-card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-basis: auto;
}

.orders-detail .expanded-card {
 display: flex;
 flex-direction: column;
 justify-content: flex-start;
 padding: 24px;
 background: #fafafa;
 border-radius: 0 0 10px 10px;
}

.orders-detail .order-detail-card .text {
  font-size: 30px;
}

.order-detail-card .ant-divider-horizontal {
  margin: 15px 0px;
}

@media screen and (max-width: 768px) {
  .orders-detail .order-detail-card {
    display: flex;
    flex-direction: column;
    grid-gap: 15px;
  }
}