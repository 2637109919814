.manage-meals{
  display: flex;
  justify-content: center;
}

.create-meal-container {
  width: 100%;
  max-width: 900px;
 align-items: center;
}

.ingredient-row {
  display: grid;
  grid-template-columns: repeat(4, 1fr) 0.5fr;
  align-items: center;
}

.macro-inputs {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  margin-bottom: 20px;
}

.ingredient-inputs {
  display: grid;
  align-items: center;
  grid-template-columns: repeat(6, 1fr);
  margin-bottom: 20px;
}

@media screen and (max-width: 768px) {
  .macro-inputs {
    display: grid;
    margin-bottom: 20px;
  }
  .nutrition-inputs div {
    margin-bottom: 20px;
  }
}
