.contact-form-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 1320px;
  margin: 80px auto;
}

.contact-form-section .title-section {
  display: grid;
  grid-gap: 40px;
  max-width: 519px;
}

.contact-form-section .title-section .icon-container {
  display: flex;
  padding: 16px;
  border-radius: 12.5px;
  border: 1px solid black;
  color: black !important;
  margin-right: 15px;
  align-items: center;
}

.contact-form-section .title-section .icon {
  font-size: 24px;
}

.contact-form-section .title-section .title {
  font-size: 48px;
}

.contact-form-section .form-subtitle {
  font-size: 16px;
}

.contact-form-section .form {
  display: grid;
  grid-gap: 20px;
  background: #f3f8ff;
  width: 100%;
  max-width: 680px;
  padding: 32px;
  border-radius: 10px;
}

.ant-input.contact-form-input {
  height: 52px;
  background-color: #f3f8ff;
}

.ant-input.contact-form-input::placeholder,
.ant-input.contact-form-text-area::placeholder {
  color: #425067;
  font-size: 14px;
}

.ant-input.contact-form-text-area {
  height: 52px;
  background-color: #f3f8ff;
}

@media screen and (max-width: 768px) {
  .contact-form-section {
    flex-direction: column;
    text-align: center;
    margin: 40px auto;
    padding: 20px;
  }

  .contact-form-section .title-section,
  .contact-form-section .form {
    width: 100%;
  }

  .contact-form-section .title-section {
    margin-bottom: 20px;
  }
  .contact-info .info {
    justify-items: flex-start;
    text-align: left;
  }
  .contact-form-section .title-section .title {
    font-size: 32px;
    line-height: 40px;
  }
}
