@import 'antd/dist/antd.css';

:root {
  --primary: #00bfb3; /* Teal */
  --primary-light: #7fe6df;
  --primary-dark: #009b91;

  --secondary: #ff7c5d; /* Coral */
  --secondary-light: #ffa694;
  --secondary-dark: #e65b3e;

  --accent: #8a4fff; /* Purple */
  --accent-light: #b894ff;
  --accent-dark: #6b3cd9;

  --background: #f8f9fa; /* Light Gray */
  --card-bg: #1a202c; /* Dark Background */
  --text-light: #ffffff;
  --text-dark: #2d3748;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  margin: 0;
  padding: 0;
}

.app {
  position: relative;
}

.app .content-wrap {
  padding-bottom: 3 rem;
}

body {
  margin: 0;
  font-family: 'Sora', sans-serif;
  background-color: #ffffff;
  font-size: 16px;
}

h1 {
  font-size: 42px;
  line-height: 1.2;
  color: #2a3e50;
  margin-bottom: 0.5em;
  font-weight: 700;
}

h1.white {
  color: white;
}

a,
a:hover {
  color: #0065ff;
}

h2 {
  font-weight: 700;
  font-size: 48px;
  letter-spacing: -0.5px;
  line-height: 1.2;
  color: black;
}

h3 {
  font-size: 24px; /* Clearly smaller than H2 */
  line-height: 1.4;
  font-weight: 700; /* Consistency in weight for all headings */
  color: black; /* Teal for a modern look */
  margin-bottom: 0.5em;
}

h4 {
  font-size: 20px; /* Subtle but still clearly a heading */
  line-height: 1.4;
  font-weight: 700;
  color: black; /* Dark slate grey for subtlety */
  margin-bottom: 0.5em;
}

p {
  margin: 0;
  padding: 0;
  line-height: 1.5;
  font-size: 18px;
  color: #425067;
  font-family: 'Sora', sans-serif;
  font-weight: 400;
  letter-spacing: 0px;
}

h1,
h2,
h3,
h4 {
}

.main-content {
  min-height: calc(100vh - 96px);
  /* padding: 0px 0px 50px 0px; */
}

.section {
  padding: 20px 0px;
}

.home-page,
.orders-page {
  width: 100%;
  max-width: 1050px;
  margin-right: auto;
  margin-left: auto;
  align-items: center;
  justify-content: space-between;
}

.ant-btn-primary,
.ant-btn-primary:focus {
  height: 56px;
  background-color: #2761f1;
  color: white;
  border: none;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.5s;
  font-size: 18px;
  border-radius: 10px;
  transition: all 0.5s ease;
  min-width: 180px;
}

.ant-btn-primary:hover {
  background-color: #2761f1 !important;
}

.ant-btn-secondary,
.ant-btn-secondary:hover,
.ant-btn-secondary:focus {
  height: 42px;
  background: transparent;
  border-color: 1px solid #4a4a4a !important;
  color: black !important;
  font-weight: 600;
}

.ant-input,
.ant-input:hover {
  height: 42px;
  border: 1px solid #d9d9d9;
  border-radius: 10px;
}

.ant-btn.ant-btn-circle.ant-btn-primary.ant-btn-lg,
.ant-btn.ant-btn-circle.ant-btn-primary.ant-btn-lg:active,
.ant-btn.ant-btn-circle.ant-btn-primary.ant-btn-lg:focus {
  height: 50px;
  width: 50px;
}

.mb-5 {
  margin-bottom: 5px;
}

.mr-10 {
  margin-right: 10px;
}

.mr-20 {
  margin-right: 20px;
}

.mr-5 {
  margin-right: 5px;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-30 {
  margin-bottom: 30px;
}

.mb-50 {
  margin-bottom: 50px;
}

.mb-100 {
  margin-bottom: 100px;
}

.mt-10 {
  margin-top: 10px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-30 {
  margin-top: 30px;
}

.mt-50 {
  margin-top: 50px;
}

.mt-60 {
  margin-top: 60px;
}

.flex {
  display: flex;
}

.grid {
  display: grid;
}

.grid.gap-10,
.flex.gap-10 {
  gap: 10px;
}

.grid.align-center {
  display: grid;
  align-items: center;
}

.grid.justify-center {
  display: grid;
  justify-items: center;
}

.flex.column {
  display: flex;
  flex-direction: column;
}

.align-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.space-between {
  justify-content: space-between;
}

.progress-circle .ant-progress-inner {
  width: 65px !important;
  height: 65px !important;
}

.landing-page .ant-progress-inner {
  width: 100px !important;
  height: 100px !important;
}

/* auth page */
.auth-page {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--background);
  border-radius: 10px;
}

.auth-container {
  display: grid;
  width: 100%;
  background-color: var(--card-bg);
  padding: 40px;
  border-radius: 10px;
  grid-gap: 10px;
  box-shadow: rgba(60, 64, 67, 0.32) 0px 1px 2px,
    rgba(60, 64, 67, 0.15) 0px 2px 6px, rgba(0, 0, 0, 0.1) 0px 1px 8px;
}

.auth-container.modal {
  box-shadow: none;
}

.auth-container.register {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 850px;
}

.auth-container.register > div {
  flex: 1; /* This will make each child take an equal share */
  max-width: 50%; /* Optional: Ensures it doesn’t exceed 50% */
}

.auth-container .text {
  font-size: 16px;
}

.auth-page .title {
  font-size: 23px;
  font-weight: 600;
  text-align: center;
}

.text {
  font-weight: 500 !important;
  font-size: 16px;
  line-height: 16px;
  color: black;
}

.bold {
  font-weight: 600 !important;
}

.label {
  font-weight: 500;
  margin-bottom: 5px;
}

.ant-picker,
.ant-input,
.ant-select {
  height: 42px;
  font-size: 18px;
}

.ant-select-selector {
  font-family: 'Inter', sans-serif !important;
}

.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  padding: 24px;
  display: flex;
  align-items: center;
  background: #f3f8ff;
  min-width: 1200px;
}

.ant-collapse-borderless > .ant-collapse-item {
  border-bottom: none;
}

.ant-collapse-content-box {
  padding: 24px 18px !important;
}

.black-title {
  font-size: 20px;
  font-weight: 600;
  color: black;
  font-style: italic;
  line-height: 1.5;
  margin-bottom: 10px;
}

.ant-select-single .ant-select-selector .ant-select-selection-search {
  /* position: relative; */
}

.tools-page {
  border-radius: 10px;
  width: 100%;
  max-width: 1150px;
  margin: 0 auto;
}

.tools-card-container {
  flex: 3;
  height: 100%;
  width: 100%;
  margin: 0 auto;
  display: grid;
  justify-content: center;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-gap: 10px;
  grid-auto-rows: 1fr;
  margin-top: 20px;
}

.breadcrumb {
  display: flex;
  align-items: center;
  font-weight: 600;
}

.three-col-input {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  grid-gap: 10px;
}

.two-col-input {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  grid-gap: 10px;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  height: 42px !important;
  align-items: center;
  border-radius: 10px;
  border: black;
}

.subtitle-text {
  font-size: 18px;
}

.ant-tabs-tab-btn {
  color: #2a3e50;
  font-size: 16px;
  font-family: 'Inter', sans-serif;
  font-weight: 600;
  text-align: left;
}

/* Change the text color of the active tab */
.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #2761f1 !important;
}

.ant-tabs-ink-bar {
  background-color: #2761f1;
}

.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
  border-color: #d9d9d9;
}

.ant-select .ant-select-focused {
  border-color: #d9d9d9;
}

.tools-section-title {
  text-align: center;
  margin-bottom: 20px;
}

.text-gradient {
  color: #2761f1;
  background: linear-gradient(135deg, #2761f1, #ff00a2);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;
  display: inline-block;
}

.underlined-title {
  position: relative;
  padding-bottom: 0px;
  display: inline-block;
}

/* .underlined-title:after {
  content: '';
  position: absolute;
  width: 100%;
  height: 4px;
  background: linear-gradient(135deg, var(--primary), var(--accent));
  left: 0;
  bottom: 0;
} */

.small-title {
  font-size: 24px;
  line-height: 34px;
  font-weight: 600;
  color: #071b2f;
  letter-spacing: 0.5px;
}

.two-column {
  display: grid;
  justify-items: center;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 20px;
}

.three-column {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-gap: 20px;
}

.grid-bullet-point {
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: start;
  margin-bottom: 10px;
}

.grid-bullet-point .bullet-point-title {
  font-weight: 600 !important;
  font-size: 18px;
  line-height: 22px;
  color: black;
}

.text-under-btn {
  font-size: 14px;
  margin-top: 10px;
  max-width: 350px;
  font-weight: 300;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.highlighted {
  background-color: #dfecff;
  padding: 2px 4px;
  display: inline;
  line-height: 1.5;
}

.underlined.light-blue {
  text-decoration: underline;
  text-decoration-color: #2761f1;
  text-decoration-thickness: 5px;
  text-underline-offset: 1px;
}

.featured-banner {
  display: flex;
  justify-content: center;
  background: #f3f8ff;
  height: 110px;
}

.featured-banner .featured-logo {
  display: grid;
  justify-items: center;
  align-items: center;
  justify-content: center;
  padding: 5px 0px 15px 0px;
}

.collapse-text {
  font-size: 18px;
}

.eyebrow-text {
  font-size: 14px;
  line-height: 17px;
  font-weight: 800;
  text-transform: uppercase;
  display: inline-block;
  letter-spacing: 1px;
  color: #4a6578;
}

.eyebrow-text.blue {
  color: rgb(39, 97, 241);
}

.eyebrow-text.white {
  color: white;
}

.light-background {
  background-color: #f1f2ff;
}

.tab-btn {
  color: #2a3e50;
  display: flex;
  border-radius: 10px;
  align-items: center;
  padding: 10px 20px;
  min-height: 50px;
  cursor: pointer;
  font-weight: bold;
  background-color: #dce0ff;
}

.tab-btn.dark {
  background: #1e1e1e;
}

.tab-btn.dark .subtitle {
  color: #dcdcdc;
}

.tab-container-horizontal {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 5px;
  margin-bottom: 10px;
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background-color: #f1f2ff;
}

.landing-page.black-background {
  background: var(--background);
}

.ant-modal.dark .ant-modal-content {
  background-color: #1e1e1e;
}

.ant-modal.dark .label {
  color: #dcdcdc;
}

.ant-modal.dark .ant-modal-close-x,
.ant-modal.dark .subtitle,
.ant-modal.dark .small-title {
  color: white;
}

.yellow-label {
  background-color: var(--primary);
  color: var(--text-light);
  padding: 0.25rem 1rem;
  border-radius: 9999px;
  font-size: 0.875rem;
}

.yellow-label p {
  color: white;
}

.yellow-label.recommended {
  position: absolute;
  top: -12px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
}

/* Base Styles for Inputs */
.auth-container .ant-input.register-input,
.auth-container .ant-input-password.register-input,
.auth-container .ant-input-affix-wrapper {
  height: 50px;
  border-radius: 5px;
  font-size: 16px;
}

/* Thicker Border on Focus */
.auth-container .ant-input.register-input:focus,
.auth-container .ant-input-password.register-input:focus,
.auth-container .ant-input-affix-wrapper:focus-within {
  border-width: 1px; /* Thicker border */
  border-color: #2761f1; /* Custom border color */
  box-shadow: none; /* Remove the default shadow */
  outline: none; /* Remove default focus outline */
  box-shadow: 0 0 8px rgba(39, 97, 241, 0.5);
}

.ant-dropdown {
  margin-top: 10px;
}

.ant-dropdown-menu {
  border-radius: 10px;
}

.ant-dropdown-menu-item {
  font-size: 14px;
  padding: 10px 20px;
}

.auth-page p {
  color: white;
}

.ant-input-affix-wrapper > input.ant-input {
  background: transparent !important;
}

.auth-page .ant-input,
.auth-page .ant-input-password,
.generate-mealplan-modal .ant-select-selector,
.questions-container .ant-select-selector,
.questions-container .ant-input,
.custom-macros-input-container .ant-input,
.custom-macros-input-container .ant-input-group-addon {
  background-color: rgba(255, 255, 255, 0.1) !important;
  color: white;
  border: black;
}

.custom-macros-input-container .ant-input-group-addon {
  border-radius: 0 10px 10px 0;
}

.generate-meal-plan-modal p {
  color: #dcdcdc;
}

.questions-container .ant-radio-button-wrapper {
  color: white;
  background: none;
}

.generate-mealplan-modal .ant-select-arrow,
.questions-container .ant-select-arrow {
  color: white;
}

.ant-modal-content {
  border-radius: 10px;
}

.auth-modal .ant-modal-body,
.auth-modal .ant-modal-content {
  padding: 0px;
  border-radius: 10px;
}

.ant-drawer-body,
.ant-drawer-header {
  background: #1c1c1c;
}

.ant-drawer-close {
  color: white;
}

.ant-drawer-body .nav-link,
.ant-drawer-body .nav-link p {
  color: white;
}

.ant-drawer-header {
  border-bottom: 1px solid #1c1c1c;
}

.ant-modal-mask {
  background-color: rgba(0, 0, 0, 0.8) !important;
}

.auth-modal .ant-modal-close-x {
  color: white;
}

.ant-slider-rail {
  background-color: #dcdcdc;
}

.ant-slider-track {
  background: linear-gradient(135deg, var(--primary), var(--accent));
}

.ant-slider-handle::after {
  position: absolute;
  top: -6px;
  right: -6px;
  bottom: -6px;
  left: -6px;
}

.shopping-slider .ant-slider-handle {
  background-color: transparent;
  border: none;
  box-shadow: none;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.shopping-slider .ant-slider-handle::before {
  content: '🥑';
  font-size: 30px;
}

/* Add specific icons for each slider */
.slider-calories .ant-slider-handle::before {
  content: '🔥';
}

.slider-protein .ant-slider-handle::before {
  content: '💪';
}

.slider-carbs .ant-slider-handle::before {
  content: '🍎';
}

.slider-fats .ant-slider-handle::before {
  content: '🥑';
}

.ant-input-affix-wrapper.transparent-input,
.transparent-input .ant-input,
.ant-input-affix-wrapper-focused.transparent-input:focus {
  background: transparent;
  color: white;
  box-shadow: none;
  font-size: 18px;
  text-align: center;
  padding: 0 11px;
  height: 30px;
}

.questionnaire-select.ant-select-dropdown {
  touch-action: pan-y;
  -webkit-overflow-scrolling: touch;
  overflow: auto !important;
}

/* .welcome-title {
  background: linear-gradient(
    135deg,
    var(--primary-light),
    var(--accent-light)
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
} */

.results-button:hover {
  transform: translateY(-3px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.banner-text {
  font-size: 16px;
  font-weight: 500;
  color: white;
  cursor: pointer;
}

@media only screen and (max-width: 768px) {
  .two-col-input {
    grid-gap: 0px;
  }
  .three-col-input {
    grid-template-columns: 1fr;
    grid-gap: 0px;
  }
  .floating-btn,
  .floating-btn:hover {
    bottom: 30px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .underlined-title:after {
    display: none;
  }
  .star-rating {
    text-align: center;
  }
  .grid-bullet-point {
    align-items: start;
  }
  .small-title {
    font-size: 24px;
    line-height: 30px;
  }
  .text-center-mobile {
    justify-content: center;
  }
  .text-under-btn {
    max-width: 200px;
  }
  .tab-btn {
    text-align: center;
  }
  .two-column {
    grid-template-columns: 1fr;
  }
  .slider.three-column {
    display: flex;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    scroll-snap-type: x mandatory;
    scrollbar-width: none;
    -ms-overflow-style: none;
  }
  .slider.three-column .global-card {
    min-width: 90%;
    scroll-snap-align: center;
  }
  .slider.three-column::-webkit-scrollbar {
    display: none;
  }
  .auth-container {
    background: none;
    padding: 40px;
    border-radius: 10px;
    grid-gap: 10px;
    box-shadow: none;
  }
  .auth-page {
    height: auto;
    background: #1c1c1c;
  }
  .auth-container.register {
    flex-direction: column;
  }
  .auth-container.register > div:nth-child(1) {
    order: 2; /* Move the first div to the second position */
  }

  .auth-container.register > div:nth-child(2) {
    order: 1; /* Move the second div to the first position */
  }
  .auth-container.register > div {
    max-width: 100%;
  }
  .main-content {
    min-height: auto;
  }
  .ant-collapse > .ant-collapse-item > .ant-collapse-header {
    min-width: 100%;
  }
  .banner-text {
    font-size: 14px;
    font-weight: 500;
    color: white;
  }
}
