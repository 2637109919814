.nav-bar {
  display: flex;
  height: 96px !important;
  padding: 15px;
  justify-content: center;
  align-items: center;
}

.nav-bar-content {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  max-width: 1340px;
}

.nav-bar .logo {
  height: 40px;
  cursor: pointer;
}

.nav-links-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.nav-link p {
  font-weight: 400;
  padding: 0 30px;
  color: #27384c;
  transition: color 0.3s ease-in-out, transform 0.3s ease-in-out;
}

.nav-link p {
  font-weight: 400;
  padding: 0 30px;
  color: #27384c;
  transition: color 0.3s ease-in-out, transform 0.3s ease-in-out;
}

.nav-link.active-tab p {
  text-decoration: underline;
  text-underline-offset: 4px;
  font-weight: 500;
  color: rgb(39, 97, 241);
}

.nav-link p:hover {
  text-decoration: underline;
  text-underline-offset: 4px;
  transform: scale(1.05);
}

@media screen and (max-width: 768px) {
  .nav-bar {
    height: auto;
    padding: 15px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .nav-bar-content {
    justify-content: space-between;
    width: 100%;
    display: flex;
  }
  .nav-link {
    margin: 0 auto;
    margin-bottom: 20px;
    cursor: pointer;
  }
  .nav-bar .logo {
    height: 30px;
  }
}
