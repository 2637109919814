.footer {
  background-color: black;
  font-size: 16px;
}

.footer-content {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  max-width: 1400px;
  padding: 80px 40px;
  margin: 0 auto;
}

.footer-content .logo {
  height: 40px;
  align-self: flex-end;
}

.footer .text {
  font-size: 14px;
  color: white;
}

.footer .footer-link {
  color: white;
  font-size: 16px;
  cursor: pointer;
}

.footer .subscribe-section {
  width: 100%;
  max-width: 500px;
}

.subscribe-form {
  display: flex;
}

.subscribe-form .ant-input {
  border-radius: 0px;
}

.footer .icon {
  color: white;
  font-size: 24px;
  cursor: pointer;
  transition: all 0.5s ease;
}

.footer .icon:hover {
  color: #2761f1;
}

@media screen and (max-width: 768px) {
  .footer-content {
    display: grid;
  }
  .footer-content .text.link.flex {
    display: grid;
    grid-gap: 10px;
    margin-bottom: 0px;
  }
  .footer .text {
    margin-bottom: 10px;
  }
  .footer .footer-section {
    margin-bottom: 20px;
  }
  .subscribe-form {
    display: grid;
  }
}
